import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnfahrtAccordions from './AnfahrtAccordions';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  withWidth,
  Paper,
  Typography,
  Button
} from '@material-ui/core';

import Fade from 'react-reveal/Fade';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptions } from '../../../contentfulOptions';

import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { initializeGA } from '../../../initializeGA';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#EDECD130'
  },
  navbarEmptySpace: {
    height: '70px'
  },
  container: {
    padding: '50px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '25px 0'
    }
  },
  iframe: {
    border: '0'
  },
  paper: {
    width: '100%',
    height: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  anfahrtAccordion: {
    margin: '50px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '40px 0 20px 0'
    }
  }
}));

const Anfahrt = (props) => {
  const classes = useStyles();
  const { width } = props;

  const [introText, setIntroText] = useState(null);
  const [accordions, setAccordions] = useState(null);
  const [cookieConsent, setCookieConsent] = useState(getCookieConsentValue());

  useEffect(() => {
    const { introText } = props;
    const { accordions } = props;

    setIntroText(introText);
    setAccordions(accordions);
  }, [props]);

  const activateCookies = () => {
    Cookies.set('CookieConsent', true);
    setCookieConsent(getCookieConsentValue());
    initializeGA();

    // If the cookie consent banner is still visible, hide it
    const cookieConsentBanner =
      document.getElementsByClassName('CookieConsent')[0];
    if (cookieConsentBanner)
      document.getElementsByClassName('CookieConsent')[0].style.visibility =
        'hidden';
  };

  return (
    <div className={classes.root}>
      <div className={classes.navbarEmptySpace}></div>
      <Container maxWidth='xl'>
        <Grid
          container
          spacing={6}
          direction={width === 'xs' ? 'column-reverse' : 'row'}
          justify='center'
          alignItems='center'
          className={classes.container}
        >
          <Grid item xs={12} sm={6} md={4} lg={4} className={classes.item}>
            <Container maxWidth='md'>
              {cookieConsent === 'true' ? (
                <iframe
                  title='alpacosi-oase'
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19237.87365988827!2d13.241666080129306!3d52.93521091326851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a90beb13db3a49%3A0xa212048d681ca10!2sBergsdorf%2C%2016792%20Zehdenick!5e0!3m2!1sde!2sde!4v1619002922389!5m2!1sde!2sde'
                  width='100%'
                  height='500px'
                  allowFullScreen=''
                  loading='lazy'
                  className={classes.iframe}
                />
              ) : (
                <Paper className={classes.paper}>
                  <Typography paragraph align='center'>
                    Bitte gib' uns Deine Zustimmung, Cookies bei Dir speichern
                    zu dürfen, um diesen Inhalt anzeigen zu können.
                  </Typography>
                  <Button
                    onClick={activateCookies}
                    variant='outlined'
                    color='secondary'
                    className={classes.button}
                    id='anfahrt-cookies-aktivieren'
                  >
                    Cookies aktivieren
                  </Button>
                </Paper>
              )}
            </Container>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            <Container maxWidth='md'>
              <Fade duration={1500}>
                <>{documentToReactComponents(introText, contentfulOptions)}</>
                <div className={classes.anfahrtAccordion}>
                  <AnfahrtAccordions accordions={accordions} />
                </div>
              </Fade>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Anfahrt.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default withWidth()(Anfahrt);

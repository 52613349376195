const isMainSeason = (date) => {
  // main season: May 15 to September 30 (including)

  if (date.getMonth() > 8) {
    return false;
  }

  if (date.getMonth() < 4) {
    return false;
  }

  if (date.getMonth() === 4 && date.getDate() < 15) {
    return false;
  }

  return true;
};

export default isMainSeason;

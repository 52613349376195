import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useScrollTrigger,
  Slide,
  Link
} from '@material-ui/core';

import NavbarDrawer from './components/NavbarDrawer';

import logo from './img/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'absolute'
  },
  appbar: {
    height: '90px',
    margin: '0',
    padding: '0'
  },
  toolbar: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  link: {
    height: '100%'
  },
  logo: {
    height: '100%',
    margin: '0 8px'
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export default function NavbarMobile(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar color='transparent' className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Link href='/' color='inherit' className={classes.link}>
              <img src={logo} alt='logo.png' className={classes.logo} />
            </Link>
            <NavbarDrawer />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  TextField,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Hidden
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Buchungsdetails from './Buchungsdetails'
import getZwischensumme from '../Functions/getZwischensumme'
import getRabatt from '../Functions/getRabatt'
import getGesamtpreis from '../Functions/getGesamtpreis'
import getMwst from '../Functions/getMwst'
import getBabyBedPrice from '../Functions/getBabyBedPrice'
import getTotalDays from '../Functions/getTotalDays'
import getCountDaysPerSeasonPerWeekdayObject from '../Functions/getCountDaysPerSeasonPerWeekdayObject'
import BookingFormHeading from './BookingFormHeading'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  form: {
    margin: '40px 0 20px 0'
  },
  buttonDiv: {
    margin: '0',
    display: 'flex'
  },
  progress: {
    marginLeft: '15px'
  },
  alert: {
    margin: '30px 0 10px 0'
  },
  checkboxes: {
    margin: '45px 0 25px 0'
  },
  checkboxItem: {
    [theme.breakpoints.down('xs')]: {
      margin: '20px 0'
    }
  },
  buchungsdetailsMobile: {
    marginTop: '20px'
  }
}))

const BookingForm = ({ bubble }) => {
  const classes = useStyles()

  const {
    calendarId,
    pricePerDayMainSeasonWeekend,
    pricePerDayMainSeasonWeekday,
    pricePerDayLowSeasonWeekend,
    pricePerDayLowSeasonWeekday,
    weeklyDiscountPercentage,
    minNights,
    maxNights
  } = bubble.fields

  const bubbleName = bubble.fields.name

  const [loading, setLoading] = useState(false)

  const [anreiseDate, setAnreiseDate] = useState(new Date())
  const [abreiseDate, setAbreiseDate] = useState(new Date())
  const [anzahlErwachsene, setAnzahlErwachsene] = useState('')
  const [anzahlKinder, setAnzahlKinder] = useState('')
  const [checkedBabyBed, setCheckedBabyBed] = useState(false)
  const [alterKind1, setAlterKind1] = useState('')
  const [alterKind2, setAlterKind2] = useState('')
  const [lastName, setLastName] = useState('')
  const [vorname, setVorname] = useState('')
  const [email, setEmail] = useState('')
  const [emailBestaetigen, setEmailBestaetigen] = useState('')
  const [emailBestaetigenError, setEmailBestaetigenError] = useState(false)
  const [emailBestaetigenErrorMsg, setEmailBestaetigenErrorMsg] = useState('')
  const [strasseHausnummer, setStrasseHausnummer] = useState('')
  const [plz, setPlz] = useState('')
  const [stadt, setStadt] = useState('')
  const [telefonnummer, setTelefonnummer] = useState('')
  const [nachricht, setNachricht] = useState('')
  const [checkedNewsletter, setCheckedNewsletter] = useState(false)
  const [checkedAgbs, setCheckedAgbs] = useState(false)

  const [bookings, setBookings] = useState([])
  const [alert, setAlert] = useState(false)

  useEffect(() => {
    // Fetch bookings that are already in the calendar
    fetch(`/.netlify/functions/getBlockedDates/?calendarId=${calendarId}`)
      .then(res => res.json())
      .then(data => {
        // Use the Date format on disabledDates
        const disabledDates = data.blockedDates.map(booking => {
          return new Date(booking)
        })

        setBookings(disabledDates)
      })
      .catch(console.log)
      .catch(console.log)
  }, [alert, calendarId])

  const disableDates = date => {
    // Disable dates that are already booked
    if (bookings.length === 0) return false

    for (let i = 0; i < bookings.length; i++) {
      if (
        date.getDate() === bookings[i].getDate() &&
        date.getMonth() === bookings[i].getMonth() &&
        date.getYear() === bookings[i].getYear()
      )
        return true
    }

    return false
  }

  const setSuccessAlert = () => {
    setAlert(
      <Alert
        severity='success'
        className={classes.alert}
        onClose={() => {
          setAlert(false)
        }}
      >
        Deine Buchungsanfrage wurde erfolgreich verschickt! Wir melden uns
        schnellstmöglich bei Dir!
      </Alert>
    )
    return true
  }

  const setErrorAlert = msg => {
    setAlert(
      <Alert
        severity='error'
        className={classes.alert}
        onClose={() => {
          setAlert(false)
        }}
      >
        {msg}
      </Alert>
    )
    return false
  }

  const setInternalServerErrorAlert = () => {
    setErrorAlert(
      'Scheinbar gibt es ein Problem mit dem Buchungssystem. Bitte versuche es später erneut.'
    )
    return false
  }

  const setOverlappingBookingsErrorAlert = () => {
    setErrorAlert(
      'Deine Buchung scheint sich mit einer bereits bestehenden Buchung zu überschneiden! Bitte versuche es mit anderen Daten.'
    )
    return false
  }

  const setMinDaysErrorAlert = () => {
    setErrorAlert(
      `Deine Daten scheinen die minimale Mietdauer von ${minNights} Tagen zu unterschreiten. Bitte versuche es mit anderen Daten.`
    )
    return false
  }

  const setMaxDaysErrorAlert = () => {
    setErrorAlert(
      `Deine Daten scheinen die maximale Mietdauer von ${maxNights} Tagen zu überschreiten. Bitte versuche es mit anderen Daten.`
    )
    return false
  }

  const setAGBErrorAlert = () => {
    setErrorAlert(
      'Bitte akzeptiere die AGBs, um Deine Buchungsanfrage verschicken zu können.'
    )
    return false
  }

  const setBookingExistsErrorAlert = () => {
    setErrorAlert(
      'Scheinbar gibt es inzwischen eine andere Buchungsanfrage für Deine ausgewählten Daten. Bitte versuche es mit anderen Buchungsdaten.'
    )
    return false
  }

  const handleAnreiseDateChange = date => {
    setAnreiseDate(date)
  }

  const handleAbreiseDateChange = date => {
    setAbreiseDate(date)
  }

  const handleAnzahlErwachseneChange = event => {
    setAnzahlErwachsene(event.target.value)
  }

  const handleAnzahlKinderChange = event => {
    setAnzahlKinder(event.target.value)
  }

  const handleCheckedBabyBedChange = e => {
    setCheckedBabyBed(!checkedBabyBed)
  }

  const handleAlterKind1Change = event => {
    setAlterKind1(event.target.value)
  }

  const handleAlterKind2Change = event => {
    setAlterKind2(event.target.value)
  }

  const handleNameChange = e => {
    setLastName(e.target.value)
  }

  const handleVornameChange = e => {
    setVorname(e.target.value)
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  const handleEmailBestaetigenChange = e => {
    setEmailBestaetigen(e.target.value)
  }

  const handleStrasseHausnummerChange = e => {
    setStrasseHausnummer(e.target.value)
  }

  const handlePlzChange = e => {
    setPlz(e.target.value)
  }

  const handleStadtChange = e => {
    setStadt(e.target.value)
  }

  const handleTelefonnummerChange = e => {
    setTelefonnummer(e.target.value)
  }

  const handleNachrichtChange = e => {
    setNachricht(e.target.value)
  }

  const handleCheckedNewsletterChange = e => {
    setCheckedNewsletter(!checkedNewsletter)
  }

  const handleCheckedAgbsChange = e => {
    setCheckedAgbs(!checkedAgbs)
  }

  const overlappingBookings = (start, end) => {
    // Iterate over every day within the booking request's date range
    for (
      let d = new Date(start);
      d <= new Date(end);
      d.setDate(d.getDate() + 1)
    ) {
      for (let i = 0; i < bookings.length; i++) {
        // Check if there is already a booking within the date range
        if (
          bookings[i].getDate() === d.getDate() &&
          bookings[i].getMonth() === d.getMonth() &&
          bookings[i].getYear() === d.getYear()
        ) {
          return true
        }
      }
    }

    // There is no booking within the date range
    return false
  }

  const formIsValid = () => {
    // Validate E-Mail
    if (email !== emailBestaetigen) {
      setEmailBestaetigenError(true)
      setEmailBestaetigenErrorMsg('Bitte bestätige deine E-Mail-Adresse')
      return false
    }

    setEmailBestaetigenError(false)
    setEmailBestaetigenErrorMsg('')

    // Validate Dates
    const oneDay = 1000 * 60 * 60 * 24
    const diffInTime = abreiseDate.getTime() - anreiseDate.getTime()
    const diffInDays = Math.round(diffInTime / oneDay)

    if (diffInDays < minNights) return setMinDaysErrorAlert()
    if (diffInDays > maxNights) return setMaxDaysErrorAlert()

    if (overlappingBookings(anreiseDate, abreiseDate))
      return setOverlappingBookingsErrorAlert()

    // Validate AGBs
    if (!checkedAgbs) return setAGBErrorAlert()

    setAlert(false)
    return true
  }

  const postEvent = async () => {
    // Check if the date range is still available in the calendar
    // If it's still free post the event to the calendar
    return fetch(`/.netlify/functions/postEvent?calendarId=${calendarId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bookingInfo: {
          anreise: anreiseDate.getTime(),
          abreise: abreiseDate.getTime(),
          name: lastName,
          vorname,
          anzahlErwachsene,
          anzahlKinder,
          alterKind1,
          alterKind2,
          checkedBabyBed,
          email,
          strasseHausnummer,
          plz,
          stadt,
          telefonnummer,
          nachricht
        }
      })
    })
  }

  const sendEmail = async () => {
    const pricesObject = {
      pricePerDayMainSeasonWeekend,
      pricePerDayMainSeasonWeekday,
      pricePerDayLowSeasonWeekend,
      pricePerDayLowSeasonWeekday
    }

    const countDaysPerSeasonPerWeekdayObject =
      getCountDaysPerSeasonPerWeekdayObject(anreiseDate, abreiseDate)
    const totalDays = getTotalDays(countDaysPerSeasonPerWeekdayObject)

    const zwischensumme = getZwischensumme(
      anreiseDate,
      abreiseDate,
      pricesObject,
      checkedBabyBed
    )
    const rabatt =
      totalDays >= 6 ? getRabatt(zwischensumme, weeklyDiscountPercentage) : 0
    const gesamtpreis = getGesamtpreis(zwischensumme, rabatt)
    const mwst = getMwst(gesamtpreis)
    const preisBabybett = getBabyBedPrice(totalDays)

    // Send the E-Mail
    return fetch('/.netlify/functions/sendBookingRequest', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bookingInfo: {
          calendarId: calendarId,
          bubbleName: bubbleName,
          anreise: anreiseDate.toString(),
          abreise: abreiseDate.toString(),
          name: lastName,
          vorname,
          anzahlErwachsene,
          anzahlKinder,
          alterKind1,
          alterKind2,
          checkedBabyBed,
          email,
          strasseHausnummer,
          plz,
          stadt,
          telefonnummer,
          nachricht,
          checkedNewsletter,
          zwischensumme,
          rabatt,
          gesamtpreis,
          mwst,
          preisBabybett
        }
      })
    })
  }

  const setSuccess = () => {
    setSuccessAlert()
    clearInputs()
    setLoading(false)
  }

  const setInternalServerError = () => {
    setInternalServerErrorAlert()
    setLoading(false)
  }

  const setBookingExistsError = () => {
    setBookingExistsErrorAlert()
    setLoading(false)
  }

  const rollbackEvent = eventId => {
    setInternalServerError()
    fetch(
      `/.netlify/functions/deleteEvent?calendarId=${calendarId}&eventId=${eventId}`,
      {
        method: 'DELETE'
      }
    )
      .then(res => res.json())
      .then(console.log)
      .catch(console.log)
      .catch(console.log)
  }

  const submitForm = async e => {
    e.preventDefault()
    setAlert(false)

    if (!formIsValid()) return
    setLoading(true)

    // Try to post the event to the calendar
    try {
      const postEventResponse = await postEvent()
      if (postEventResponse.status === 409) return setBookingExistsError()
      if (postEventResponse.status !== 200) return setInternalServerError()

      // Remember the eventId. If the e-mail can't be sent the calendar event needs to be deleted (rollback)
      const { data } = await postEventResponse.json()
      const eventId = data.id

      // Try to send the e-mail
      try {
        const sendEmailResponse = await sendEmail()
        if (sendEmailResponse.status !== 200) return rollbackEvent(eventId)
      } catch (err) {
        return rollbackEvent(eventId)
      }
    } catch (err) {
      return setInternalServerError()
    }

    setSuccess()
  }

  const clearInputs = () => {
    const now = new Date()
    setAnreiseDate(now)
    setAbreiseDate(now)
    setAnzahlErwachsene('')
    setAnzahlKinder('')
    setAlterKind1('')
    setAlterKind2('')
    setLastName('')
    setVorname('')
    setEmail('')
    setEmailBestaetigen('')
    setStrasseHausnummer('')
    setPlz('')
    setStadt('')
    setTelefonnummer('')
    setNachricht('')
    setCheckedNewsletter(false)
    setCheckedAgbs(false)
    setCheckedBabyBed(false)
  }

  return (
    <div className={classes.root}>
      <BookingFormHeading />
      {alert}
      <Container maxWidth='lg'>
        <form
          name='buchen'
          className={classes.form}
          id='form'
          action='/danke'
          onSubmit={submitForm}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      id='anreise'
                      label='Anreise'
                      value={anreiseDate}
                      onChange={handleAnreiseDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      autoOk
                      disablePast
                      shouldDisableDate={disableDates}
                      fullWidth
                      disabled={loading ? true : false}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant='inline'
                      format='dd/MM/yyyy'
                      margin='normal'
                      id='abreise'
                      label='Abreise'
                      value={abreiseDate}
                      onChange={handleAbreiseDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      autoOk
                      disablePast
                      shouldDisableDate={disableDates}
                      minDate={anreiseDate}
                      minDateMessage='Das Abreisedatum muss vor dem Anreisedatum liegen!'
                      fullWidth
                      disabled={loading ? true : false}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    required
                    fullWidth
                    disabled={loading ? true : false}
                  >
                    <InputLabel id='anzahl-erwachsene-label'>
                      Anzahl Erwachsene
                    </InputLabel>
                    <Select
                      labelId='anzahl-erwachsene-label'
                      id='anzahl-erwachsene'
                      value={anzahlErwachsene}
                      onChange={handleAnzahlErwachseneChange}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    required
                    fullWidth
                    disabled={loading ? true : false}
                  >
                    <InputLabel id='anzahl-kinder-label'>
                      Anzahl Kinder
                    </InputLabel>
                    <Select
                      labelId='anzahl-kinder-label'
                      id='anzahl-kinder'
                      value={anzahlKinder}
                      onChange={handleAnzahlKinderChange}
                    >
                      <MenuItem value={0}>0</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {anzahlKinder >= 1 && (
                  <>
                    <Grid item xs={6}>
                      <FormControl
                        className={classes.formControl}
                        required
                        fullWidth
                        disabled={loading ? true : false}
                      >
                        <InputLabel id='alter-kind-1-label'>
                          Alter Kind 1
                        </InputLabel>
                        <Select
                          labelId='alter-kind-1-label'
                          id='alter-kind-1'
                          value={alterKind1}
                          onChange={handleAlterKind1Change}
                        >
                          <MenuItem value={0}>0-3</MenuItem>
                          <MenuItem value={4}>4-12</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {anzahlKinder === 2 && (
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.formControl}
                      required
                      fullWidth
                      disabled={loading ? true : false}
                    >
                      <InputLabel id='alter-kind-2-label'>
                        Alter Kind 2
                      </InputLabel>
                      <Select
                        labelId='alter-kind-2-label'
                        id='alter-kind-2'
                        value={alterKind2}
                        onChange={handleAlterKind2Change}
                      >
                        <MenuItem value={0}>0-3</MenuItem>
                        <MenuItem value={4}>4-12</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {anzahlKinder >= 1 && (
                  <Grid item xs={12} className={classes.checkboxItem}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedBabyBed}
                          onChange={handleCheckedBabyBedChange}
                          name='checkedBabyBed'
                        />
                      }
                      label='Extra Babybett (kostet 10€ pro Nacht)'
                      disabled={loading ? true : false}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={12}>
                  <TextField
                    id='name'
                    label='Name'
                    name='name'
                    value={lastName}
                    onChange={handleNameChange}
                    required
                    fullWidth
                    className={classes.textField}
                    disabled={loading ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <TextField
                    id='vorname'
                    label='Vorname'
                    name='vorname'
                    value={vorname}
                    onChange={handleVornameChange}
                    required
                    fullWidth
                    className={classes.textField}
                    disabled={loading ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <TextField
                    id='email'
                    label='E-Mail'
                    name='email'
                    value={email}
                    onChange={handleEmailChange}
                    required
                    fullWidth
                    className={classes.textField}
                    disabled={loading ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <TextField
                    id='email-bestaetigen'
                    label='E-Mail bestätigen'
                    name='email-bestaetigen'
                    value={emailBestaetigen}
                    onChange={handleEmailBestaetigenChange}
                    required
                    fullWidth
                    className={classes.textField}
                    error={emailBestaetigenError}
                    helperText={emailBestaetigenErrorMsg}
                    disabled={loading ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <Buchungsdetails
                  calendarId={calendarId}
                  anreise={anreiseDate}
                  abreise={abreiseDate}
                  pricePerDayMainSeasonWeekend={pricePerDayMainSeasonWeekend}
                  pricePerDayMainSeasonWeekday={pricePerDayMainSeasonWeekday}
                  pricePerDayLowSeasonWeekend={pricePerDayLowSeasonWeekend}
                  pricePerDayLowSeasonWeekday={pricePerDayLowSeasonWeekday}
                  weeklyDiscountPercentage={weeklyDiscountPercentage}
                  checkedBabyBed={checkedBabyBed}
                />
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6}>
              <TextField
                id='strasseHausnummer'
                label='Straße + Haus-Nr.'
                name='strasseHausnummer'
                value={strasseHausnummer}
                onChange={handleStrasseHausnummerChange}
                required
                fullWidth
                className={classes.textField}
                disabled={loading ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='plz'
                label='PLZ'
                name='plz'
                value={plz}
                onChange={handlePlzChange}
                required
                fullWidth
                className={classes.textField}
                disabled={loading ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='stadt'
                label='Stadt'
                name='stadt'
                value={stadt}
                onChange={handleStadtChange}
                required
                fullWidth
                className={classes.textField}
                disabled={loading ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='telefonnummer'
                label='Telefonnummer'
                name='telefonnummer'
                value={telefonnummer}
                onChange={handleTelefonnummerChange}
                fullWidth
                className={classes.textField}
                disabled={loading ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                id='nachricht'
                label='Nachricht'
                name='nachricht'
                value={nachricht}
                onChange={handleNachrichtChange}
                rows={5}
                fullWidth
                className={classes.textField}
                disabled={loading ? true : false}
              />
            </Grid>
            <Hidden mdUp>
              <Grid item xs={12} className={classes.buchungsdetailsMobile}>
                <Buchungsdetails
                  calendarId={calendarId}
                  anreise={anreiseDate}
                  abreise={abreiseDate}
                  pricePerDayMainSeasonWeekend={pricePerDayMainSeasonWeekend}
                  pricePerDayMainSeasonWeekday={pricePerDayMainSeasonWeekday}
                  pricePerDayLowSeasonWeekend={pricePerDayLowSeasonWeekend}
                  pricePerDayLowSeasonWeekday={pricePerDayLowSeasonWeekday}
                  weeklyDiscountPercentage={weeklyDiscountPercentage}
                  checkedBabyBed={checkedBabyBed}
                />
              </Grid>
            </Hidden>
            <div className={classes.checkboxes}>
              <Grid item xs={12} className={classes.checkboxItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedNewsletter}
                      onChange={handleCheckedNewsletterChange}
                      name='checkedNewsletter'
                    />
                  }
                  label='Ich möchte E-Mails und Angebote von der Alpacosi Oase erhalten'
                  disabled={loading ? true : false}
                />
              </Grid>
              <Grid item xs={12} className={classes.checkboxItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedAgbs}
                      onChange={handleCheckedAgbsChange}
                      name='checkedAgbs'
                    />
                  }
                  label='Ich habe die AGBs gelesen und stimme diesen mit Absenden des Formulares zu'
                  required
                  disabled={loading ? true : false}
                />
              </Grid>
            </div>
            <Grid item xs={12}>
              <div className={classes.buttonDiv}>
                <Button
                  type='submit'
                  variant='outlined'
                  className={classes.button}
                  disabled={loading ? true : false}
                >
                  Senden
                </Button>
                {loading && <CircularProgress className={classes.progress} />}
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  )
}

export default BookingForm

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import NaturErlebenAccordions from './components/NaturErlebenAccordions';

import Fade from 'react-reveal/Fade';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptions } from '../../contentfulOptions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '130px 0 60px 0',
    backgroundColor: '#EDECD130'
  },
  accordionDiv: {
    margin: '30px 0'
  },
  img: {
    height: '100%',
    width: '100%'
  }
}));

const NaturErleben = () => {
  const classes = useStyles();

  const [introText, setIntroText] = useState(null);
  const [accordions, setAccordions] = useState(null);
  const [imgURL, setImgURL] = useState(null);
  const [imgTitle, setImgTitle] = useState(null);

  useEffect(() => {
    fetch('/.netlify/functions/getContentfulData/?content_type=naturErleben')
      .then((res) => res.json())
      .then(({ data }) => setState(data))
      .catch(console.log);
  }, []);

  const setState = (data) => {
    const { introText } = data;
    const { accordions } = data;
    const { image } = data;

    setIntroText(introText);
    setAccordions(accordions);
    setImgURL(image?.fields.file.url);
    setImgTitle(image?.fields.title);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Grid container spacing={5} alignItems='flex-start' justify='center'>
          <Grid item xs={12} sm={6} md={7}>
            <Fade duration={1500}>
              <>{documentToReactComponents(introText, contentfulOptions)}</>
              <div className={classes.accordionDiv}>
                <NaturErlebenAccordions accordions={accordions} />
              </div>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Fade duration={1500}>
              <img src={imgURL} alt={imgTitle} className={classes.img} />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default NaturErleben;

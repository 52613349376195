import getCountDaysPerSeasonPerWeekdayObject from './getCountDaysPerSeasonPerWeekdayObject';
import getTotalDays from '../Functions/getTotalDays';
import getBabyBedPrice from './getBabyBedPrice';

const getZwischensumme = (anreise, abreise, pricesObject, checkedBabyBed) => {
  const {
    countDaysMainSeasonWeekend,
    countDaysMainSeasonWeekday,
    countDaysLowSeasonWeekend,
    countDaysLowSeasonWeekday
  } = getCountDaysPerSeasonPerWeekdayObject(anreise, abreise);

  const {
    pricePerDayMainSeasonWeekend,
    pricePerDayMainSeasonWeekday,
    pricePerDayLowSeasonWeekend,
    pricePerDayLowSeasonWeekday
  } = pricesObject;

  const preisMainSeasonWeekend =
    countDaysMainSeasonWeekend * pricePerDayMainSeasonWeekend;
  const preisMainSeasonWeekday =
    countDaysMainSeasonWeekday * pricePerDayMainSeasonWeekday;
  const preisLowSeasonWeekend =
    countDaysLowSeasonWeekend * pricePerDayLowSeasonWeekend;
  const preisLowSeasonWeekday =
    countDaysLowSeasonWeekday * pricePerDayLowSeasonWeekday;

  const totalDays = getTotalDays(
    getCountDaysPerSeasonPerWeekdayObject(anreise, abreise)
  );

  const preisBabyBed = checkedBabyBed ? getBabyBedPrice(totalDays) : 0;

  return (
    preisMainSeasonWeekend +
    preisMainSeasonWeekday +
    preisLowSeasonWeekend +
    preisLowSeasonWeekday +
    preisBabyBed
  );
};

export default getZwischensumme;

import React from 'react';
import { useStyles } from './PricesStyles';
import { Typography } from '@material-ui/core';

const Prices = ({ bubble }) => {
  const classes = useStyles();

  const {
    pricePerDayMainSeasonWeekend,
    pricePerDayMainSeasonWeekday,
    pricePerDayLowSeasonWeekend,
    pricePerDayLowSeasonWeekday
  } = bubble.fields;

  return (
    <>
      <div className={classes.featurePrice}>
        <Typography variant='h4' className={classes.inline} component='p'>
          ab {pricePerDayLowSeasonWeekday}€
        </Typography>
        <Typography paragraph className={classes.inline}>
          / Nacht
        </Typography>
        <Typography variant='caption' color='textSecondary' component='p'>
          Während der Hauptsaison gilt am Wochenende:{' '}
          {pricePerDayMainSeasonWeekend}€ / unter der Woche:{' '}
          {pricePerDayMainSeasonWeekday}€ <br />
          Während der Nebensaison gilt am Wochenende:{' '}
          {pricePerDayLowSeasonWeekend}€ / unter der Woche:{' '}
          {pricePerDayLowSeasonWeekday}€
        </Typography>
      </div>
    </>
  );
};

export default Prices;

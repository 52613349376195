import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '120px 0 30px 0'
  },
  ul: {
    margin: '0 40px'
  }
}));

const AGB = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Typography variant='h2'>
          Allgemeine Geschäftsbedingungen (AGB) - „Alpacosi Oase“
        </Typography>
        <Typography paragraph>
          Vermieter: Alpacosi UG, Julian Dittmann, Bergsdorfer Dorfstraße 7,
          16792 Zehdenick
        </Typography>
        <Typography variant='h5'>1. Anreise / Abreise</Typography>
        <Typography paragraph>
          An-und Abreise ist zu jedem Wochentag möglich. Die Anreise kann ab 15
          Uhr erfolgen. Die Abreise muss am Abreisetag bis spätestens 10.30 Uhr
          erfolgen. Andere An- und Abreisezeiten können mit uns individuell
          vereinbart werden, sofern dies möglich ist. Solltest Du am Anreisetag
          bis 22.00 Uhr nicht erscheinen, gilt der Vertrag nach einer Frist von
          48 Stunden ohne Benachrichtigung an uns als gekündigt. Wir oder unsere
          Vertreter können dann über das Objekt frei verfügen. Eine anteilige
          oder komplette Rückzahlung der Miete aufgrund verfrühter Abreise
          erfolgt grundsätzlich nicht.
        </Typography>
        <Typography variant='h5'>2. Sonderwünsche und Nebenabreden</Typography>
        <Typography paragraph>
          Sind grundsätzlich möglich. Sie müssen aber von unserer Seite
          schriftlich bestätigt werden. Haustiere sind nicht erlaubt.
        </Typography>
        <Typography variant='h5'>3. Bezahlung</Typography>
        <Typography paragraph>
          Die Bezahlung ist per Überweisung oder in Bar fällig. Bei Überweisung
          ist eine Anzahlung in Höhe von mindestens 20% des Mietbetrags
          innerhalb von sieben Tagen nach Erhalt der Buchungsunterlagen zur
          Zahlung fällig. Nach der erfolgten Anzahlung wird 30 Tage vor
          Reiseantritt die Zahlung des Restbetrages fällig. Bei Barzahlung ist
          der genaue Betrag bei Ankunft fällig. Werden die Zahlungsfristen nicht
          eingehalten, so können wir vom Vertrag zurücktreten. Die Nichtzahlung
          gilt als Rücktritt und berechtigt zur Neuvermietung. Nebenkosten für
          Energiekosten, Wasser, PKW-Stellplatz, Abfall werden nicht erhoben.
        </Typography>
        <Typography variant='h5'>4. Rücktritt</Typography>
        <Typography paragraph>
          Du kannst jederzeit vom Vertrag zurücktreten. Der Rücktritt muss
          schriftlich per Mail erfolgen. Im Falle des Rücktritts bist Du zum
          Ersatz des uns entstandenen Schadens verpflichtet:
        </Typography>
        <Typography paragraph>
          <ul className={classes.ul}>
            <li>
              vom Tag der Buchungsbestätigung durch uns bis zum 121. Tag vor
              Mietbeginn keine Entschädigung
            </li>
            <li>
              vom 120. Tag bis zum 61. Tag vor Mietbeginn Höhe der Anzahlung -
              20% des Betrages
            </li>
            <li>
              vom 60. Tag bis zum 15. Tag vor Mietbeginn 50% des Gesamtpreises
            </li>
            <li>
              vom 14. Tag bis zum 8. Tag vor Mietbeginn 80% des Gesamtpreises
            </li>
          </ul>
        </Typography>
        <Typography paragraph>
          Bei einem Rücktritt weniger als acht Tage vor Mietbeginn ist der volle
          Reisepreis zu zahlen. Es zählt jeweils das Empfangsdatum Deiner
          Rücktrittsnachricht. Bereits eingezahlte Beträge werden verrechnet.
          Eine Ersatzperson, die zu genannten Bedingungen in Deinem Vertrag
          eintritt, kann von Dir gestellt werden. Eine schriftliche
          Benachrichtigung genügt. Solltest du aus einem triftigen Grund oder
          Krankheit zurücktreten müssen, werden wir mit Dir einen alternativen
          Buchungszeitraum festlegen.
        </Typography>
        <Typography variant='h5'>5. Pflichten des Mieters</Typography>
        <Typography paragraph>
          Aus ökologischen Gründen bitten wir dich ausschließlich das
          bereitgestellte biologisch abbaubare Duschgel, Shampoo und Spülmittel
          zu verwenden. Zudem sind die gemieteten Sachen (Bubble Zelt, Inventar
          und Außenanlagen) pfleglich zu behandeln. Wenn während des
          Mietverhältnisses Schäden am Bubble Zelt und / oder dessen Inventar
          auftreten, bist Du verpflichtet, dies unverzüglich anzuzeigen. Am
          Abreisetag sind von dir persönliche Gegenstände zu entfernen, der
          Hausmüll ist in die vorgesehenen Behälter zu entsorgen, Geschirr ist
          sauber und gewaschen in den Küchenschränken zu lagern. Bitte verlasse
          die Unterkunft besenrein.
        </Typography>
        <Typography variant='h5'>6. Datenschutz</Typography>
        <Typography paragraph>
          Du erklärst dich damit einverstanden, dass im Rahmen des mit Dir
          abgeschlossenen Vertrages notwendige Daten über deine Person
          gespeichert, geändert und / oder gelöscht werden. Alle persönlichen
          Daten werden absolut vertraulich behandelt.
        </Typography>
        <Typography variant='h5'>7. Haftung</Typography>
        <Typography paragraph>
          Für eine Beeinflussung des Mietobjektes durch höhere Gewalt, durch
          landesübliche Strom- und Wasserausfälle und Unwetter wird nicht
          gehaftet. Ebenso wird nicht gehaftet bei Eintritt unvorhersehbarer
          oder unvermeidbarer Umstände wie z.B. behördlicher Anordnung,
          plötzlicher Baustelle oder für Störungen durch naturbedingte und
          örtliche Begebenheiten. Sollten wider Erwarten Probleme auftreten,
          werden wir bemüht sein, diese schnellstmöglich zu beheben. Deine An-
          und Abreise erfolgt in eigener Verantwortung und Haftung. Wir haften
          nicht für persönliche Gegenstände bei Diebstahl oder Feuer. Für
          mutwillige Zerstörungen bzw. Schäden haftest Du in vollem Umfang.
        </Typography>
        <Typography variant='h5'>8. Schlussbestimmungen</Typography>
        <Typography paragraph>
          Fotos und Text auf der Webseite bzw. im Flyer dienen der realistischen
          Beschreibung. Die Fotos sind urheberrechtlich geschützt und dürfen
          nicht von Dritten verwendet werden. Bildquelle: Alena & Felix
          Fotografie) Sollten eine oder mehrere Bestimmungen dieser
          Geschäftsbedingungen unwirksam sein oder werden, berührt dies die
          Wirksamkeit der übrigen Bedingungen nicht. Die unwirksame Regelung ist
          durch eine wirksame zu ersetzen, die dem wirtschaftlichen und
          rechtlichen Willen der Vertragsparteien am nahesten kommt. Es gilt
          deutsches Recht. Gerichtsstand und Erfüllungsort ist der Wohnort des
          Vermieters.
        </Typography>
      </Container>
    </div>
  );
};

export default AGB;

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, Container, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '30px 0px',
    margin: '0',
    backgroundColor: theme.palette.background.main
  },
  item: {
    textAlign: 'center'
  }
}));

const FootSection = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Grid container spacing={0}>
          <Grid item xs={4} className={classes.item}>
            <Link href='/agb' color='inherit'>
              AGB
            </Link>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Link href='/faq' color='inherit'>
              FAQ
            </Link>
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <Link href='/impressum' color='inherit'>
              Impressum
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FootSection;

import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    paddingTop: '90px',
    [theme.breakpoints.down('xs')]: {
      padding: '65px 0'
    }
  }
}));

const IntroSection = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [introText, setIntroText] = useState(null);

  useEffect(() => {
    const { introText } = props.introData;

    setIntroText(introText);
  }, [props.introData]);

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <Fade duration={1500}>
          <Typography variant='h2' align='center'>
            {introText}
          </Typography>
        </Fade>
      </Container>
    </div>
  );
};

export default IntroSection;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Card,
  CardHeader,
  withWidth
} from '@material-ui/core';

import EssenTrinkenAccordion from './components/EssenTrinkenAccordion';

import Fade from 'react-reveal/Fade';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptions } from '../../contentfulOptions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '80px 0',
    margin: '50px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '65px 0 0 0',
      margin: '50px 0 15px 0'
    }
  },
  card: {
    padding: '20px',
    margin: '20px 0'
  },
  img: {
    height: '100%',
    width: '100%'
  }
}));

const EssenTrinken = (props) => {
  const classes = useStyles();
  const { width } = props;

  const [introText, setIntroText] = useState(null);
  const [accordions, setAccordions] = useState(null);
  const [essenTrinkenImages, setEssenTrinkenImages] = useState(null);
  const [imgURL1, setImgURL1] = useState(null);
  const [imgTitle1, setImgTitle1] = useState(null);
  const [imgHeading1, setImgHeading1] = useState(null);
  const [imgSubHeading1, setImgSubHeading1] = useState(null);
  const [imgURL2, setImgURL2] = useState(null);
  const [imgTitle2, setImgTitle2] = useState(null);
  const [imgHeading2, setImgHeading2] = useState(null);
  const [imgSubHeading2, setImgSubHeading2] = useState(null);

  useEffect(() => {
    fetch('/.netlify/functions/getContentfulData/?content_type=essenTrinken')
      .then((res) => res.json())
      .then(({ data }) => setState(data))
      .catch(console.log);
  }, []);

  const setState = (data) => {
    const { introText } = data;
    const { accordions } = data;
    const { images } = data;

    setIntroText(introText);
    setAccordions(accordions);
    setEssenTrinkenImages(images);
    setImgURL1(images?.[0].fields.image.fields.file.url);
    setImgTitle1(images?.[0].fields.image.fields.file.title);
    setImgHeading1(images?.[0].fields.heading);
    setImgSubHeading1(images?.[0].fields.subheading);
    setImgURL2(images?.[1]?.fields.image.fields.file.url);
    setImgTitle2(images?.[1]?.fields.image.fields.file.title);
    setImgHeading2(images?.[1].fields.heading);
    setImgSubHeading2(images?.[1].fields.subheading);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Grid
          container
          spacing={width === 'xs' ? 0 : 6}
          alignItems='flex-start'
          justify='center'
        >
          <Grid item xs={12} sm={6} md={8}>
            <Fade duration={1500}>
              <>{documentToReactComponents(introText, contentfulOptions)}</>
              <EssenTrinkenAccordion accordions={accordions} />
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Fade duration={1500}>
              <Card className={classes.card}>
                <CardHeader title={imgHeading1} subheader={imgSubHeading1} />
                <img src={imgURL1} alt={imgTitle1} className={classes.img} />
              </Card>
            </Fade>
            {essenTrinkenImages?.length === 2 && (
              <Fade duration={1500}>
                <Card className={classes.card}>
                  <CardHeader title={imgHeading2} subheader={imgSubHeading2} />
                  <img src={imgURL2} alt={imgTitle2} className={classes.img} />
                </Card>
              </Fade>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withWidth()(EssenTrinken);

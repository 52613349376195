import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline'
  },
  featurePrice: {
    marginBottom: '10px'
  }
}));

export { useStyles };

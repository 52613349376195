import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Card,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '50px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0 10px 0'
    }
  },
  formHeading: {
    textAlign: 'center',
    margin: '0px 0px 40px 0px',
    '& h3': {
      margin: 0
    }
  },
  card: {
    padding: '70px'
  },
  form: {},
  textField: {
    margin: '0px 30px 0px 0px'
  },
  buttonDiv: {
    display: 'flex',
    margin: '30px 0px 0px 0px'
  },
  progress: {
    marginLeft: '15px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  alert: {
    marginBottom: '25px'
  }
}));

const KontaktForm = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nachricht, setNachricht] = useState('');

  const [alert, setAlert] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNachrichtChange = (e) => {
    setNachricht(e.target.value);
  };

  const submitForm = (e) => {
    setLoading(true);

    e.preventDefault();

    // Send the E-Mail
    fetch('/.netlify/functions/sendContactRequest', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        email,
        nachricht
      })
    })
      .then((res) => {
        if (res.status === 200) {
          // Set Success Alert
          setAlert(
            <Alert
              severity='success'
              className={classes.alert}
              onClose={() => {
                setAlert(false);
              }}
            >
              Deine Kontaktanfrage wurde erfolgreich verschickt! Wir melden uns
              schnellstmöglich bei dir!
            </Alert>
          );
          clearInputs();
        } else {
          // Set internal server error Alert
          setAlert(
            <Alert
              severity='error'
              className={classes.alert}
              onClose={() => {
                setAlert(false);
              }}
            >
              Scheinbar gibt es ein Problem mit dem Kontaktsystem. Bitte
              versuche es später erneut.
            </Alert>
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // Set internal server error Alert
        setAlert(
          <Alert
            severity='error'
            className={classes.alert}
            onClose={() => {
              setAlert(false);
            }}
          >
            Scheinbar gibt es ein Problem mit dem Kontaktsystem. Bitte versuche
            es später erneut.
          </Alert>
        );
        setLoading(false);
      });
  };

  const clearInputs = () => {
    setName('');
    setEmail('');
    setNachricht('');
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='sm'>
        <Fade duration={1500}>
          <div className={classes.formHeading}>
            <Typography variant='h3'>Hast Du noch Fragen?</Typography>
            <Typography variant='subtitle1'>
              Schick' uns eine Nachricht!
            </Typography>
          </div>
        </Fade>
        <Fade duration={1500}>
          <Card className={classes.card}>
            {alert}
            <form
              name='kontakt'
              className={classes.form}
              id='form'
              action='/danke'
              onSubmit={submitForm}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='name'
                    label='Name'
                    name='name'
                    value={name}
                    onChange={handleNameChange}
                    required
                    fullWidth
                    className={classes.textField}
                    disabled={loading ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='email'
                    label='E-Mail'
                    name='email'
                    value={email}
                    onChange={handleEmailChange}
                    required
                    fullWidth
                    className={classes.textField}
                    disabled={loading ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    id='nachricht'
                    label='Nachricht'
                    name='nachricht'
                    value={nachricht}
                    onChange={handleNachrichtChange}
                    rows={7}
                    required
                    fullWidth
                    disabled={loading ? true : false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.buttonDiv}>
                    <Button
                      type='submit'
                      variant='outlined'
                      disabled={loading ? true : false}
                    >
                      Senden
                    </Button>
                    {loading && (
                      <CircularProgress className={classes.progress} />
                    )}
                  </div>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Fade>
      </Container>
    </div>
  );
};

export default KontaktForm;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import Rechnungsposition from './Rechnungsposition';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px'
    }
  },
  hr: {
    margin: '0 0 15px 0'
  }
}));

const Buchungsdetails = (props) => {
  const classes = useStyles();

  const [countDaysMainSeasonWeekend, setCountDaysMainSeasonWeekend] =
    useState();
  const [countDaysMainSeasonWeekday, setCountDaysMainSeasonWeekday] =
    useState();
  const [countDaysLowSeasonWeekend, setCountDaysLowSeasonWeekend] = useState();
  const [countDaysLowSeasonWeekday, setCountDaysLowSeasonWeekday] = useState();
  const [totalPriceMainSeasonWeekend, setTotalPriceMainSeasonWeekend] =
    useState();
  const [totalPriceMainSeasonWeekday, setTotalPriceMainSeasonWeekday] =
    useState();
  const [totalPriceLowSeasonWeekend, setTotalPriceLowSeasonWeekend] =
    useState();
  const [totalPriceLowSeasonWeekday, setTotalPriceLowSeasonWeekday] =
    useState();
  const [totalDays, setTotalDays] = useState();
  const [totalPriceBabyBed, setTotalPriceBabyBed] = useState();
  const [zwischensumme, setZwischensumme] = useState();
  const [rabatt, setRabatt] = useState();
  const [gesamtpreis, setGesamtpreis] = useState();
  const [mwst, setMwst] = useState();

  const { anreise, abreise, checkedBabyBed } = props;

  useEffect(() => {
    // Fetch booking price info from backend
    fetch(
      `/.netlify/functions/getBookingPriceInfo/?calendarId=${
        props.calendarId
      }&anreise=${anreise.getTime()}&abreise=${abreise.getTime()}&checkedBabyBed=${checkedBabyBed}`
    )
      .then((res) => res.json())
      .then((bookingPriceInfo) => {
        const {
          countDaysMainSeasonWeekend,
          countDaysMainSeasonWeekday,
          countDaysLowSeasonWeekend,
          countDaysLowSeasonWeekday,
          totalPriceMainSeasonWeekend,
          totalPriceMainSeasonWeekday,
          totalPriceLowSeasonWeekend,
          totalPriceLowSeasonWeekday,
          totalDays,
          totalPriceBabyBed,
          zwischensumme,
          rabatt,
          gesamtpreis,
          mwst
        } = bookingPriceInfo;

        setCountDaysMainSeasonWeekend(countDaysMainSeasonWeekend);
        setCountDaysMainSeasonWeekday(countDaysMainSeasonWeekday);
        setCountDaysLowSeasonWeekend(countDaysLowSeasonWeekend);
        setCountDaysLowSeasonWeekday(countDaysLowSeasonWeekday);
        setTotalPriceMainSeasonWeekend(totalPriceMainSeasonWeekend);
        setTotalPriceMainSeasonWeekday(totalPriceMainSeasonWeekday);
        setTotalPriceLowSeasonWeekend(totalPriceLowSeasonWeekend);
        setTotalPriceLowSeasonWeekday(totalPriceLowSeasonWeekday);
        setTotalDays(totalDays);
        setTotalPriceBabyBed(totalPriceBabyBed);
        setZwischensumme(zwischensumme);
        setRabatt(rabatt);
        setGesamtpreis(gesamtpreis);
        setMwst(mwst);
      })
      .catch(console.log)
      .catch(console.log);
  }, [anreise, abreise, checkedBabyBed, props.calendarId]);

  return (
    <Card className={classes.root}>
      <Typography variant='h4'>Buchungsdetails</Typography>
      <hr className={classes.hr} />
      <Typography paragraph>Anreise: {anreise.toLocaleDateString()}</Typography>
      <Typography paragraph>Abreise: {abreise.toLocaleDateString()}</Typography>
      <hr className={classes.hr} />
      {countDaysMainSeasonWeekend > 0 && (
        <Rechnungsposition
          anzahlNaechte={countDaysMainSeasonWeekend}
          wochenende={true}
          hauptsaison={true}
          preis={totalPriceMainSeasonWeekend}
        />
      )}
      {countDaysMainSeasonWeekday > 0 && (
        <Rechnungsposition
          anzahlNaechte={countDaysMainSeasonWeekday}
          wochenende={false}
          hauptsaison={true}
          preis={totalPriceMainSeasonWeekday}
        />
      )}
      {countDaysLowSeasonWeekend > 0 && (
        <Rechnungsposition
          anzahlNaechte={countDaysLowSeasonWeekend}
          wochenende={true}
          hauptsaison={false}
          preis={totalPriceLowSeasonWeekend}
        />
      )}
      {countDaysLowSeasonWeekday > 0 && (
        <Rechnungsposition
          anzahlNaechte={countDaysLowSeasonWeekday}
          wochenende={false}
          hauptsaison={false}
          preis={totalPriceLowSeasonWeekday}
        />
      )}
      {checkedBabyBed && (
        <Rechnungsposition
          anzahlNaechte={totalDays}
          babybett
          preis={totalPriceBabyBed}
        />
      )}
      <hr className={classes.hr} />
      {totalDays >= 6 && (
        <>
          <Rechnungsposition gesamt preis={zwischensumme} />
          <Rechnungsposition rabatt preis={rabatt} />
        </>
      )}
      <Rechnungsposition gesamt strong={true} preis={gesamtpreis} />
      <Rechnungsposition mwst preis={mwst} />
    </Card>
  );
};

export default Buchungsdetails;

import React from 'react';
import { useStyles } from './BookingHeadingStyles';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptions } from '../../../contentfulOptions';

const BookingHeading = ({ introText }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {documentToReactComponents(introText, contentfulOptions)}
    </div>
  );
};

export default BookingHeading;

import React from 'react';
import { Typography } from '@material-ui/core';

const BookingFormHeading = () => {
  return (
    <>
      <Typography variant='h5'>Buchungsanfrage</Typography>
      <Typography paragraph>
        Wir werden deine Anfrage so schnell wie möglich beantworten. Bitte
        beachte, dass das Versenden des Anfrageformulars eine Vorreservierung
        darstellt. Du erhältst von uns die finale Buchungsbestätigung per Mail.
      </Typography>
    </>
  );
};

export default BookingFormHeading;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rechnungsposition: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const Rechnungsposition = (props) => {
  const classes = useStyles();

  const {
    anzahlNaechte,
    wochenende,
    hauptsaison,
    preis,
    gesamt,
    mwst,
    rabatt,
    strong,
    babybett
  } = props;

  const preisFixed = preis?.toFixed(2).replace('.', ',');

  return (
    <>
      {gesamt && (
        <div className={classes.rechnungsposition}>
          <Typography paragraph>
            {strong ? <strong>Gesamt:</strong> : 'Zwischensumme:'}
          </Typography>
          <Typography paragraph>
            {strong ? <strong>{preisFixed}€</strong> : preisFixed + '€'}
          </Typography>
        </div>
      )}
      {rabatt && (
        <div className={classes.rechnungsposition}>
          <Typography paragraph>Rabatt:</Typography>
          <Typography paragraph>{preisFixed}€</Typography>
        </div>
      )}
      {mwst && (
        <div className={classes.rechnungsposition}>
          <Typography paragraph>inkl. Mwst:</Typography>
          <Typography paragraph>{preisFixed}€</Typography>
        </div>
      )}
      {!gesamt && !mwst && !rabatt && !babybett && (
        <div className={classes.rechnungsposition}>
          <Typography paragraph>
            {anzahlNaechte} {anzahlNaechte !== 1 ? 'Nächte' : 'Nacht'}{' '}
            {wochenende ? 'Wochenende' : 'unter der Woche'}{' '}
            {hauptsaison ? '(Hauptsaison):' : '(Nebensaison):'}
          </Typography>
          <Typography paragraph>{preisFixed}€</Typography>
        </div>
      )}
      {babybett && (
        <div className={classes.rechnungsposition}>
          <Typography paragraph>
            {anzahlNaechte} {anzahlNaechte !== 1 ? 'Nächte' : 'Nacht'}{' '}
            {'Babybett'}
          </Typography>
          <Typography paragraph>{preisFixed}€</Typography>
        </div>
      )}
    </>
  );
};

export default Rechnungsposition;

import React, { useEffect, useState } from 'react';
import HeroSection from './components/HeroSection';
import IntroSection from './components/IntroSection';
import TabSection from './components/TabSection';
import ItemSection from './components/ItemSection';
import NachhaltigkeitSection from './components/NachhaltigkeitSection';
import AccordionSection from './components/AccordionSection';
import NaturErlebenSection from './components/NaturErlebenSection';

import { Hidden } from '@material-ui/core';

const Home = () => {
  const [heroData, setHeroData] = useState({});
  const [introData, setIntroData] = useState({});
  const [tabsData, setTabsData] = useState([]);
  const [nachhaltigkeitData, setNachhaltigkeitData] = useState({});
  const [flexItemsData, setFlexItemsData] = useState([]);
  const [naturErlebenData, setNaturErlebenData] = useState({});

  useEffect(() => {
    fetch('/.netlify/functions/getContentfulData/?content_type=home')
      .then((res) => res.json())
      .then(({ data }) => setState(data))
      .catch(console.log);
  }, []);

  const setState = (data) => {
    const { heroHeading, heroSubheading, heroMedia, heroTextColor } = data;
    const { introText } = data;
    const { tabs } = data;
    const { nachhaltigkeitHeading, nachhaltigkeitText, nachhaltigkeitImages } =
      data;
    const { flexItems } = data;
    const { naturErlebenText, naturErlebenImage } = data;

    setHeroData({
      heroHeading,
      heroSubheading,
      heroMedia,
      heroTextColor
    });

    setIntroData({
      introText
    });

    setTabsData({
      tabs
    });

    setNachhaltigkeitData({
      nachhaltigkeitHeading,
      nachhaltigkeitText,
      nachhaltigkeitImages
    });

    setFlexItemsData({
      flexItems
    });

    setNaturErlebenData({
      naturErlebenText,
      naturErlebenImage
    });
  };

  return (
    <div>
      <HeroSection heroData={heroData} />
      <IntroSection introData={introData} />
      <Hidden only='xs'>
        <TabSection tabsData={tabsData} />
      </Hidden>
      <Hidden smUp>
        <AccordionSection tabsData={tabsData} />
      </Hidden>
      <NachhaltigkeitSection nachhaltigkeitData={nachhaltigkeitData} />
      <ItemSection flexItemsData={flexItemsData} />
      <NaturErlebenSection naturErlebenData={naturErlebenData} />
    </div>
  );
};

export default Home;

import React, { useState, useEffect } from 'react';
import { useStyles } from './BookingStyles';
import { Container } from '@material-ui/core';

import Fade from 'react-reveal/Fade';

import NavbarEmptySpace from '../../layout/components/NavbarEmptySpace';
import BookingHeading from './components/BookingHeading';
import Bubble from './components/Bubble';

const Booking = () => {
  const classes = useStyles();

  const [introText, setIntroText] = useState(null);
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = '/.netlify/functions/getContentfulData/?content_type=buchen';

      const res = await fetch(url);
      const { data } = await res.json();

      setState(data);
    };

    fetchData();
  }, []);

  const setState = ({ introText, bubbles }) => {
    setIntroText(introText);
    setBubbles(bubbles);
  };

  return (
    <div className={classes.root}>
      <NavbarEmptySpace />
      <div className={classes.booking}>
        <Container maxWidth='md'>
          <Fade duration={1500}>
            <BookingHeading introText={introText} />
          </Fade>
          <Fade duration={1500}>
            <>
              {bubbles.length > 0 &&
                bubbles.map((bubble) => {
                  return <Bubble bubble={bubble} />;
                })}
            </>
          </Fade>
        </Container>
      </div>
    </div>
  );
};

export default Booking;

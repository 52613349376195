import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    padding: '35px 0'
  },
  card: {
    padding: '25px'
  },
  cardHeading: {
    margin: '20px 0 0 0'
  },
  container: {
    width: '210px',
    margin: '25px auto'
  },
  item: {
    textAlign: 'center'
  },
  link: {
    color: 'black'
  }
}));

const SocialMediaSection = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <Container maxWidth='xs'>
        <Fade duration={1500}>
          <div className={classes.cardHeading}>
            <Typography variant='h5' align='center'>
              Komm raus in die Natur!
            </Typography>
            <Typography variant='subtitle1' align='center'>
              Schreib' uns auf Social Media!
            </Typography>
          </div>
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={6} className={classes.item}>
              <a
                href='https://www.facebook.com/alpacosi.oase/'
                target='_blank'
                rel='noreferrer'
                className={classes.link}
              >
                <FacebookIcon fontSize='large' />
              </a>
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <a
                href='https://www.instagram.com/alpacosi_oase/'
                target='_blank'
                rel='noreferrer'
                className={classes.link}
              >
                <InstagramIcon fontSize='large' />
              </a>
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </div>
  );
};

export default SocialMediaSection;

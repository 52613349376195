import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '@material-ui/core';

const H5 = ({ children }) => (
  <Typography variant='h5' style={{ margin: '10px 0' }}>
    {children}
  </Typography>
);
const H6 = ({ children }) => (
  <Typography variant='h6' style={{ margin: '8px 0' }}>
    {children}
  </Typography>
);
const Paragraph = ({ children }) => (
  <Typography variant='body2' component='p'>
    {children}
  </Typography>
);

export const contentfulOptionsCardText = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>
  }
};

import { useEffect } from 'react';
import './App.css';
import { makeStyles, responsiveFontSizes } from '@material-ui/core/styles';

import Navbar from './layout/Navbar';
import NavbarMobile from './layout/NavbarMobile';
import FootSection from './layout/FootSection';
import SocialMediaSection from './layout/SocialMediaSection';

import Home from './pages/Home/Home';
import KontaktAnfahrt from './pages/KontaktAnfahrt/KontaktAnfahrt';
import Galerie from './pages/Galerie/Galerie';
import Booking from './pages/Booking/Booking';
import EssenTrinken from './pages/EssenTrinken/EssenTrinken';
import NaturErleben from './pages/NaturErleben/NaturErleben';
import Uebernachtung from './pages/Uebernachtung/Uebernachtung';
import AGB from './pages/AGB/AGB';
import FAQ from './pages/FAQ/FAQ';
import Impressum from './pages/Impressum/Impressum';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Hidden } from '@material-ui/core';

import 'fontsource-crimson-text';

import { getCookieConsentValue } from 'react-cookie-consent';
import CookieConsentBanner from './layout/CookieConsentBanner';
import { initializeGA } from './initializeGA';

let theme = createMuiTheme({
  typography: {
    fontFamily: 'crimson-text',
    h1: {
      fontSize: '55px'
    },
    h2: {
      fontSize: '35px',
      margin: '20px 0'
    },
    h3: {
      fontSize: '30px',
      margin: '15px 0'
    },
    h4: {
      fontSize: '25px',
      margin: '10px 0'
    },
    body2: {
      margin: '0 0 15px 0',
      fontSize: '14px'
    }
  },
  palette: {
    primary: {
      main: '#EDECD1',
      lighter: '#EDECD1F5',
      light: '#EDECD1B0'
    },
    secondary: {
      main: '#C2C8A7',
      lighter: '#C2C8A730',
      light: '#C2C8A7C0'
    },
    background: {
      default: 'white',
      main: '#EDECD130'
    }
  }
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  contentContainer: {
    minHeight: 'calc(100vh - 312px)'
  },
  footerPin: {
    position: 'relative',
    left: 0,
    bottom: 0
  }
}));

function App() {
  const classes = useStyles();

  // Initialize Google Analytics
  useEffect(() => {
    if (getCookieConsentValue() === 'true') initializeGA();
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className='App'>
          <div className={classes.root}>
            <div className={classes.contentContainer}>
              <Hidden smDown>
                <Navbar />
              </Hidden>
              <Hidden mdUp>
                <NavbarMobile />
              </Hidden>
              <Switch>
                <Route exact path='/'>
                  <Home />
                </Route>
                <Route exact path='/kontakt-anfahrt'>
                  <KontaktAnfahrt />
                </Route>
                <Route exact path='/galerie'>
                  <Galerie />
                </Route>
                <Route exact path='/buchen'>
                  <Booking />
                </Route>
                <Route exact path='/uebernachtung'>
                  <Uebernachtung />
                </Route>
                <Route exact path='/essen-trinken'>
                  <EssenTrinken />
                </Route>
                <Route exact path='/natur-erleben'>
                  <NaturErleben />
                </Route>
                <Route exact path='/agb'>
                  <AGB />
                </Route>
                <Route exact path='/faq'>
                  <FAQ />
                </Route>
                <Route exact path='/impressum'>
                  <Impressum />
                </Route>
              </Switch>
            </div>
            <CookieConsentBanner />
            <div className={classes.footerPin}>
              <SocialMediaSection />
              <FootSection />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;

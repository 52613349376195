import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '120px 0 30px 0'
  }
}));

const FAQ = () => {
  const classes = useStyles();

  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    fetch('/.netlify/functions/getContentfulData/?content_type=faq')
      .then((res) => res.json())
      .then(({ data }) => setState(data))
      .catch(console.log);
  }, []);

  const setState = (data) => {
    const { questions } = data;

    setQuestions(questions);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Typography variant='h2'>FAQ</Typography>
        <>
          {questions?.map?.((question, index) => {
            return (
              <React.Fragment key={index}>
                <Typography variant='h5'>{question.fields.question}</Typography>
                <Typography paragraph>{question.fields.answer}</Typography>
              </React.Fragment>
            );
          })}
        </>
      </Container>
    </div>
  );
};

export default FAQ;

import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Card,
  Grid,
  withWidth
} from '@material-ui/core';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'white'
    },
    padding: '70px 0px'
  },
  card: {
    padding: '30px',
    textAlign: 'center'
  },
  imgItem: {
    height: '250px'
  },
  img: {
    height: '100%',
    width: '100%',
    display: 'block'
  }
}));

const ItemSection = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [flexItems, setFlexItems] = useState([]);

  useEffect(() => {
    setFlexItems(props.flexItemsData.flexItems);
  }, [props.flexItemsData]);

  const { width } = props;

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <Grid container spacing={3}>
          {flexItems?.map?.((flexItem, index) => {
            const { heading, text, image } = flexItem.fields;
            const imageURL = image.fields.file.url;
            const imageName = image.fields.title;

            return (
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
                <Fade
                  left={
                    width !== 'xs' && width !== 'sm' && index === 0
                      ? true
                      : false
                  }
                  right={
                    width !== 'xs' && width !== 'sm' && index === 2
                      ? true
                      : false
                  }
                  duration={1500}
                >
                  <Card className={classes.card}>
                    <Grid container spacing={3} alignItems='center'>
                      <Grid item xs={12} sm={4} md={12}>
                        <img
                          src={imageURL}
                          alt={imageName}
                          className={classes.img}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} md={12}>
                        <Typography variant='h4'>{heading}</Typography>
                        <Typography variant='body2'>{text}</Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Fade>
              </Grid>
            );
          })}
          {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Fade
              left={width !== 'xs' && width !== 'sm' ? true : false}
              duration={1500}
            >
              <Card className={classes.card}>
                <Grid container spacing={3} alignItems='center'>
                  <Grid item xs={12} sm={4} md={12}>
                    <img
                      src={unsereGeschichte}
                      alt='unsere-geschichte.jpg'
                      className={classes.img}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={12}>
                    <Typography variant='h4'>Unsere Geschichte</Typography>
                    <Typography variant='body2'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Minus perspiciatis qui quo voluptatum quidem molestias
                      reiciendis ullam ea iusto ducimus quos, natus esse
                      veritatis, rerum ratione pariatur neque dignissimos
                      asperiores? Nam labore neque omnis non expedita nostrum
                      veritatis reprehenderit aut facere vitae tenetur,
                      architecto sequi voluptates unde ipsum eos maxime incidunt
                      sint sunt deserunt delectus molestias perspiciatis beatae
                      adipisci. Minima nesciunt voluptatibus maiores ad.
                      Molestias laborum labore repudiandae sequi, corrupti sit
                      obcaecati! Natus a fugiat sint dolore, modi ipsam illum
                      esse minima ducimus consequatur impedit autem in iste
                      quasi minus tempore cum! Omnis, numquam autem iste error,
                      magnam ut maxime placeat aspernatur porro mollitia
                      deleniti sit laudantium eligendi libero veritatis!
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Fade duration={1500}>
              <Card className={classes.card}>
                <Grid container spacing={3} alignItems='center'>
                  <Grid item xs={12} sm={4} md={12}>
                    <img
                      src={lokalePartner}
                      alt='lokale-partner.jpg'
                      className={classes.img}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={12}>
                    <Typography variant='h4'>Lokale Partner</Typography>
                    <Typography variant='body2'>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Iure nostrum blanditiis repellendus, consequuntur ducimus
                      labore, culpa, dolor ut et nesciunt nobis consectetur
                      soluta! Doloremque perspiciatis exercitationem
                      necessitatibus explicabo! Error debitis quia
                      exercitationem eius rem. Consequuntur quisquam in
                      inventore aperiam nihil quos optio animi esse fugit harum
                      dolorum molestias obcaecati a corporis dolorem dolor vel,
                      voluptates molestiae, dolore, architecto labore maxime ut
                      numquam! Perspiciatis ducimus cupiditate enim, autem
                      quisquam dignissimos voluptate eveniet repellendus natus
                      dolorem consequuntur ratione ipsa eligendi quam laboriosam
                      rem officiis ipsam deleniti? Qui, autem. Fugiat
                      voluptatibus mollitia sed quia repudiandae quae, id
                      numquam quidem fugit nobis reiciendis qui! Incidunt,
                      similique corrupti nostrum ab dolor doloremque blanditiis,
                      dolorem possimus aspernatur ratione nisi rerum, assumenda
                      corporis reiciendis ea? Enim, autem.
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Card className={classes.card}>
              <Fade
                right={width !== 'xs' && width !== 'sm' ? true : false}
                duration={1500}
              >
                <Grid container spacing={3} alignItems='center'>
                  <Grid item xs={12} sm={4} md={12} className={classes.imgItem}>
                    <img src={cbd} alt='cbd.jpg' className={classes.img} />
                  </Grid>
                  <Grid item xs={12} sm={8} md={12}>
                    <Typography variant='h4'>CBD-Anbau</Typography>
                    <Typography variant='body2'>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Voluptatibus laudantium obcaecati corrupti iste? Nam vitae
                      nulla facere tempore? Laboriosam iste tempora adipisci ut
                      nobis voluptates ratione officia tempore odit placeat
                      natus ducimus quibusdam sint cumque, sed, odio eveniet
                      assumenda aliquam omnis sunt sequi. Debitis sapiente
                      consequuntur dolorem quaerat sit animi ratione.
                      Perferendis ipsa vero quis! Animi velit laborum dolore!
                      Ratione cumque, temporibus earum odio rem quo recusandae
                      possimus repudiandae necessitatibus. Eum, assumenda
                      ducimus, ab dolores sapiente eaque quidem facilis ipsa
                      ullam harum voluptatibus tempore quia sed nostrum!
                      Molestias, aliquam sunt!
                    </Typography>
                  </Grid>
                </Grid>
              </Fade>
            </Card>
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default withWidth()(ItemSection);

import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from '@material-ui/core';

const H2 = ({ children }) => <Typography variant='h2'>{children}</Typography>;
const H3 = ({ children }) => <Typography variant='h3'>{children}</Typography>;
const Paragraph = ({ children }) => (
  <Typography paragraph>{children}</Typography>
);

export const contentfulOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>
  }
};

import React, { useState, useEffect } from 'react';
import TabSection from './TabSection';

const Tabs = (props) => {
  const [tabs, setTabs] = useState(null);

  useEffect(() => {
    const { tabs } = props.tabsData;

    setTabs(tabs);
  }, [props.tabsData]);

  return (
    <>
      {tabs?.map?.((tab, index) => {
        return <TabSection tabData={tab} key={index} index={index} />;
      })}
    </>
  );
};

export default Tabs;

import isMainSeason from './isMainSeason';
import isWeekend from './isWeekend';

const getCountDaysPerSeasonPerWeekdayObject = (anreise, abreise) => {
  const anreiseDate = new Date(anreise);
  anreiseDate.setHours(0);
  anreiseDate.setMinutes(0);
  anreiseDate.setSeconds(0);
  anreiseDate.setMilliseconds(0);

  const abreiseDate = new Date(abreise);
  abreiseDate.setHours(0);
  abreiseDate.setMinutes(0);
  abreiseDate.setSeconds(0);
  abreiseDate.setMilliseconds(0);

  let countDaysMainSeasonWeekend = 0;
  let countDaysMainSeasonWeekday = 0;
  let countDaysLowSeasonWeekend = 0;
  let countDaysLowSeasonWeekday = 0;

  // Iterate over every day within the booking request's date range
  for (
    let date = anreiseDate;
    date < abreiseDate;
    date.setDate(date.getDate() + 1)
  ) {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);

    if (isMainSeason(date) && isWeekend(nextDay)) countDaysMainSeasonWeekend++;
    if (isMainSeason(date) && !isWeekend(nextDay)) countDaysMainSeasonWeekday++;
    if (!isMainSeason(date) && isWeekend(nextDay)) countDaysLowSeasonWeekend++;
    if (!isMainSeason(date) && !isWeekend(nextDay)) countDaysLowSeasonWeekday++;
  }

  return {
    countDaysMainSeasonWeekend,
    countDaysMainSeasonWeekday,
    countDaysLowSeasonWeekend,
    countDaysLowSeasonWeekday
  };
};

export default getCountDaysPerSeasonPerWeekdayObject;

const getTotalDays = (countDaysPerSeasonPerWeekdayObject) => {
  const {
    countDaysMainSeasonWeekend,
    countDaysMainSeasonWeekday,
    countDaysLowSeasonWeekend,
    countDaysLowSeasonWeekday
  } = countDaysPerSeasonPerWeekdayObject;

  return (
    countDaysMainSeasonWeekend +
    countDaysMainSeasonWeekday +
    countDaysLowSeasonWeekend +
    countDaysLowSeasonWeekday
  );
};

export default getTotalDays;

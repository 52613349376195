import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  card: {
    width: '100%',
    marginTop: '60px'
  },
  cardHeader: {
    marginTop: '20px'
  },
  img: {
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '60px'
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    margin: '15px',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}));

export { useStyles };

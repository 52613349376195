import React from 'react';
import { useStyles } from './AmenityIconsStyles';

import WcIcon from '@material-ui/icons/Wc';
import KitchenIcon from '@material-ui/icons/Kitchen';
import PowerIcon from '@material-ui/icons/Power';

const AmenityIcons = ({ amenities }) => {
  const classes = useStyles();

  return (
    <>
      {amenities.includes('WC') && (
        <WcIcon fontSize='default' className={classes.amenityIcon} />
      )}
      {amenities.includes('Küche') && (
        <KitchenIcon fontSize='default' className={classes.amenityIcon} />
      )}
      {amenities.includes('Strom') && (
        <PowerIcon fontSize='default' className={classes.amenityIcon} />
      )}
    </>
  );
};

export default AmenityIcons;

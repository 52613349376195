import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Bounce from 'react-reveal/Bounce';

const useStyles = makeStyles((theme) => ({
  hero: {
    height: '100vh',
    position: 'relative',
    top: '0'
  },
  content: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 20px',
    zIndex: 20
  }
}));

const HeroSection = (props) => {
  const classes = useStyles();
  const [heroHeading, setHeroHeading] = useState(null);
  const [heroSubheading, setHeroSubheading] = useState(null);
  const [heroMediaURL, setHeroMediaURL] = useState(null);
  const [heroTextColor, setHeroTextColor] = useState(null);

  useEffect(() => {
    const { heroHeading, heroSubheading, heroMedia, heroTextColor } =
      props.heroData;

    setHeroHeading(heroHeading);
    setHeroSubheading(heroSubheading);
    setHeroMediaURL(heroMedia?.fields.file.url);
    setHeroTextColor(heroTextColor);
  }, [props.heroData]);

  return (
    <div
      className={classes.hero}
      style={{
        background: `url(${heroMediaURL}) no-repeat center center/cover`
      }}
    >
      <div className={classes.content} style={{ color: heroTextColor }}>
        <Bounce right duration={1500}>
          <Typography variant='h1'>{heroHeading}</Typography>
          <Typography variant='h6'>{heroSubheading}</Typography>
        </Bounce>
      </div>
    </div>
  );
};

export default HeroSection;

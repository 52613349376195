import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  booking: {
    padding: '70px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0'
    },
    backgroundColor: '#EDECD130'
  }
}));

export { useStyles };

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Hero from './components/Hero';
import Tabs from './components/Tabs';

import { Container } from '@material-ui/core';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    top: '0',
    padding: '0 0 70px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  },
  heroSection: {
    padding: '140px 0 70px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '110px 0 40px 0'
    },
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#EDECD130'
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '80px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '35px'
    }
  },
  button: {
    margin: '15px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: 'auto',
      width: '170px'
    }
  },
  img: {
    height: '100%',
    width: '100%'
  }
}));

const Uebernachtung = (props) => {
  const classes = useStyles();

  const [heroData, setHeroData] = useState({});
  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    fetch('/.netlify/functions/getContentfulData/?content_type=uebernachtung')
      .then((res) => res.json())
      .then(({ data }) => setState(data))
      .catch(console.log);
  }, []);

  const setState = (data) => {
    const { heroText } = data;
    const { tabs } = data;

    setHeroData({
      heroText
    });

    setTabsData({
      tabs
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.heroSection}>
        <Container maxWidth='md'>
          <Fade duration={1500}>
            <Hero heroData={heroData} />
          </Fade>
        </Container>
      </div>
      <Tabs tabsData={tabsData} />
    </div>
  );
};

export default Uebernachtung;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { List, Divider, ListItem } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: 250,
    height: '100%',
    padding: '70px 30px 30px 10px'
  },
  fullList: {
    width: 'auto'
  },
  menuButton: {
    margin: '0 15px'
  },
  button: {
    margin: '5px 0',
    padding: '10px 15px'
  }
});

export default function NavbarDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key='kontakt'>
          <Button href='/kontakt-anfahrt' className={classes.button}>
            Kontakt & Anfahrt
          </Button>
        </ListItem>
        <ListItem button key='galerie'>
          <Button href='/galerie' className={classes.button}>
            Galerie
          </Button>
        </ListItem>

        <ListItem button key='Gutscheine'>
          <Button href='https://forms.gle/GYG1G7dkgC19fTqz5'  target="_blank" rel="noopener noreferrer" className={classes.button}>
            Gutscheine      
          </Button>
        </ListItem>
        
        <ListItem button key='buchen'>
          <Button
            href='/buchen'
            variant='outlined'
            color='default'
            className={classes.button}
          >
            Buchen
          </Button>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key='uebernachtung'>
          <Button href='/uebernachtung' className={classes.button} size='small'>
            Übernachtung
          </Button>
        </ListItem>
        <ListItem button key='essenTrinken'>
          <Button href='/essen-trinken' className={classes.button} size='small'>
            Essen/Trinken
          </Button>
        </ListItem>
        <ListItem button key='naturErleben'>
          <Button href='/natur-erleben' className={classes.button} size='small'>
            Natur erleben
          </Button>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='primary'
            aria-label='menu'
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon fontSize='large' />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className={classes.drawer}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tabs,
  Tab,
  Box,
  Container,
  Grid,
  Typography,
  withWidth,
  Button
} from '@material-ui/core';
import TabCarousel from '../../../layout/TabCarousel';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '100px 0',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: '40px 0'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  tab: {
    margin: 'auto'
  },
  tabpanel: {
    padding: '30px 0 20px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0'
    }
  },
  ul: {
    listStylePosition: 'inside',
    padding: '0 20px',
    margin: '20px 0'
  },
  li: {
    fontSize: '16px',
    margin: '8px 0'
  },
  button: {
    margin: '15px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '32px auto 0px auto',
      width: '170px'
    }
  },
  img: {
    [theme.breakpoints.up('md')]: {
      marginTop: '80px'
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth='xl'>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

const TabSection = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { width } = props;

  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [ausstattung, setAusstattung] = useState(null);
  const [images, setImages] = useState(null);
  const [linkToBuchen, setLinkToBuchen] = useState(null);
  const [index, setIndex] = useState(null);

  useEffect(() => {
    const { title, text, ausstattung, images, linkToBuchen } =
      props.tabData.fields;

    setTitle(title);
    setText(text);
    setAusstattung(ausstattung);
    setImages(images);
    setLinkToBuchen(linkToBuchen);
    setIndex(props.index);
  }, [props.tabData, props.index]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: index % 2 === 0 ? 'white' : '#EDECD130' }}
      id={title}
    >
      <div className={classes.content}>
        <Container maxWidth='lg'>
          <Grid
            container
            direction={index % 2 === 0 ? 'row' : 'row-reverse'}
            spacing={
              width === 'lg' || width === 'xl' || width === 'md' ? 10 : 4
            }
            alignItems='flex-start'
            justify='center'
          >
            <Grid item md={6} xs={12} className={classes.img}>
              <Fade duration={1500}>
                <TabCarousel images={images} />
              </Fade>
            </Grid>
            <Grid item md={6} xs={12}>
              <Fade duration={1500}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor='secondary'
                  textColor='secondary'
                >
                  <Tab label={title} className={classes.tab} />
                  <Tab label='Ausstattung' className={classes.tab} />
                </Tabs>
                <TabPanel value={value} index={0} className={classes.tabpanel}>
                  <div>
                    <Typography
                      variant='h3'
                      align={
                        width === 'sm' || width === 'xs' ? 'center' : 'left'
                      }
                    >
                      {title}
                    </Typography>
                    <Typography paragraph align='left'>
                      {text}
                    </Typography>
                    {linkToBuchen && (
                      <Button
                        href='/buchen'
                        variant='outlined'
                        color='secondary'
                        className={classes.button}
                      >
                        Jetzt Buchen
                      </Button>
                    )}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.tabpanel}>
                  <div>
                    <Typography
                      variant='h3'
                      align={
                        width === 'sm' || width === 'xs' ? 'center' : 'left'
                      }
                    >
                      Ausstattung
                    </Typography>

                    <ul className={classes.ul}>
                      {ausstattung?.map?.((a, index) => {
                        return (
                          <Typography
                            key={index}
                            className={classes.li}
                            align='left'
                          >
                            <li>{a}</li>
                          </Typography>
                        );
                      })}
                    </ul>
                  </div>
                </TabPanel>
              </Fade>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

TabSection.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default withWidth()(TabSection);

import React, { useState, useEffect } from 'react';
import Anfahrt from './components/Anfahrt';
import KontaktForm from './components/KontaktForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  kontakt: {
    padding: '50px 0px 20px 0'
  }
}));

const KontaktAnfahrt = () => {
  const classes = useStyles();

  const [introText, setIntroText] = useState(null);
  const [accordions, setAccordions] = useState(null);

  useEffect(() => {
    fetch('/.netlify/functions/getContentfulData/?content_type=anfahrt')
      .then((res) => res.json())
      .then(({ data }) => setState(data))
      .catch(console.log);
  }, []);

  const setState = (data) => {
    const { introText } = data;
    const { accordions } = data;

    setIntroText(introText);
    setAccordions(accordions);
  };

  return (
    <div className={classes.root}>
      <Anfahrt introText={introText} accordions={accordions} />
      <div className={classes.kontakt}>
        <KontaktForm />
      </div>
    </div>
  );
};

export default KontaktAnfahrt;

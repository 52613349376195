import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '120px 0 30px 0'
  }
}));

const Impressum = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Typography variant='h2'>Impressum</Typography>
        <Typography paragraph>
          Alpacosi UG (haftungsbeschränkt)
          <br />
          Geschäftsführer: Julian Dittmann
          <br />
          Bergsdorfer Dorfstraße 7
          <br />
          16792 Zehdenick
          <br />
          Telefon: 0176 83104880
          <br />
          E-Mail: info@alpacosi-oase.de
          <br />
          <br />
          HRB 13344 NP
          <br />
          <br />
          Bildnachweis: Alena & Felix Fotografie
        </Typography>
      </Container>
    </div>
  );
};

export default Impressum;

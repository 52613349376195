import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Container, withWidth, Button } from '@material-ui/core';

import Fade from 'react-reveal/Fade';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptions } from '../../../contentfulOptions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '60px 0',
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.background.main,
      padding: '60px 0',
      textAlign: 'center'
    }
  },
  button: {
    margin: '15px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '20px auto 0 auto',
      width: '170px'
    }
  },
  img: {
    height: '100%',
    width: '100%'
  }
}));

const NaturErlebenSection = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { width } = props;

  const [naturErlebenText, setNaturErlebenText] = useState(null);
  const [naturErlebenImage, setNaturErlebenImage] = useState(null);

  useEffect(() => {
    const { naturErlebenText, naturErlebenImage } = props.naturErlebenData;

    setNaturErlebenText(naturErlebenText);
    setNaturErlebenImage(naturErlebenImage);
  }, [props.naturErlebenData]);

  return (
    <div className={classes.root} id='naturErleben'>
      <Container maxWidth='lg'>
        <Grid
          container
          spacing={width === 'xs' ? 6 : 7}
          alignItems='center'
          justify='center'
        >
          <Grid item xs={12} md={5}>
            <Fade left duration={1500}>
              <img
                src={naturErlebenImage?.fields.file.url}
                alt={naturErlebenImage?.fields.title}
                className={classes.img}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} md={7}>
            <Fade right duration={1500}>
              <>
                {documentToReactComponents(naturErlebenText, contentfulOptions)}
              </>
              <Button
                href='/natur-erleben'
                variant='outlined'
                color='secondary'
                className={classes.button}
              >
                Mehr erfahren
              </Button>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

NaturErlebenSection.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default withWidth()(NaturErlebenSection);

import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Accordion,
  Typography,
  Container,
  Grid,
  Button
} from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TabCarousel from '../../../layout/TabCarousel';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    width: '100%',
    paddingBottom: '90px'
  },
  sectionHeading: {
    margin: '0px 0 60px 0'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  details: {
    margin: '7px 0 37px 0'
  },
  img: {
    display: 'flex',
    height: '100%',
    width: '80%',
    margin: 'auto'
  },
  button: {
    display: 'flex',
    margin: 'auto',
    width: '70%'
  }
}));

export default function AccordionSection(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [tabs, setTabs] = useState(null);

  useEffect(() => {
    setTabs(props.tabsData.tabs);
  }, [props.tabsData]);

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <Fade duration={1500}>
          <ul>
            {tabs?.map?.((tab, index) => {
              const { title, images, text, linkTo } = tab.fields;

              return (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography className={classes.heading}>{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.details}>
                    <Container maxWidth='sm'>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Fade duration={1500}>
                            <TabCarousel images={images} height='200px' />
                          </Fade>
                        </Grid>
                        <Grid item xs={12}>
                          <Fade duration={1500}>
                            <Typography variant='body2' align='center'>
                              {text}
                            </Typography>
                          </Fade>
                        </Grid>
                        <Grid item xs={12}>
                          <Fade duration={1500}>
                            <Button
                              href={linkTo}
                              variant='outlined'
                              color='secondary'
                              className={classes.button}
                            >
                              Mehr erfahren
                            </Button>
                          </Fade>
                        </Grid>
                      </Grid>
                    </Container>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </ul>
        </Fade>
      </Container>
    </div>
  );
}

import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import CookieConsent from 'react-cookie-consent';
import { initializeGA } from '../initializeGA';

const CookieConsentBanner = () => {
  const theme = useTheme();

  return (
    <CookieConsent
      location='bottom'
      enableDeclineButton
      flipButtons
      style={{
        background: theme.palette.primary.lighter,
        alignItems: 'center',
        color: 'black'
      }}
      buttonStyle={{
        background: theme.palette.secondary.main,
        borderRadius: '2px',
        color: 'black',
        fontSize: '16px',
        fontFamily: 'crimson-text'
      }}
      buttonText='Alles klar'
      declineButtonStyle={{
        background: theme.palette.secondary.lighter,
        borderRadius: '2px',
        color: 'black',
        fontSize: '16px',
        fontFamily: 'crimson-text'
      }}
      declineButtonText='Lieber nicht'
      onAccept={initializeGA}
    >
      <Typography>
        Wir würden Dich gerne besser kennenlernen und nutzen deshalb
        Analyse-Tools. 🍪
      </Typography>
    </CookieConsent>
  );
};

export default CookieConsentBanner;

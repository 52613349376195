import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  withWidth,
  Hidden,
  Typography
} from '@material-ui/core';

import EcoIcon from '@material-ui/icons/Eco';
import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0px',
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.main
    }
  },
  container: {},
  imgItem: { width: '100%', height: '100%' },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  textItem: {
    textAlign: 'center'
  }
}));

const NachhaltigkeitSection = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [nachhaltigkeitHeading, setNachhaltigkeitHeading] = useState(null);
  const [nachhaltigkeitImages, setNachhaltigkeitImages] = useState(null);
  const [nachhaltigkeitText, setNachhaltigkeitText] = useState(null);

  useEffect(() => {
    const { nachhaltigkeitHeading, nachhaltigkeitText, nachhaltigkeitImages } =
      props.nachhaltigkeitData;

    setNachhaltigkeitHeading(nachhaltigkeitHeading);
    setNachhaltigkeitImages(nachhaltigkeitImages);
    setNachhaltigkeitText(nachhaltigkeitText);
  }, [props.nachhaltigkeitData]);

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Grid
          container
          spacing={5}
          className={classes.container}
          alignItems='center'
        >
          <Grid
            item
            sm={12}
            md={nachhaltigkeitImages?.length === 2 ? 6 : 12}
            className={classes.imgItem}
          >
            <Fade left duration={1500}>
              <img
                src={nachhaltigkeitImages?.[0].fields.file.url}
                alt={nachhaltigkeitImages?.[0].fields.title}
                className={classes.img}
              />
            </Fade>
          </Grid>
          {nachhaltigkeitImages?.length === 2 && (
            <Hidden smDown>
              <Grid item sm={6} className={classes.imgItem}>
                <Fade right duration={1500}>
                  <img
                    src={nachhaltigkeitImages?.[1].fields.file.url}
                    alt={nachhaltigkeitImages?.[1].fields.title}
                    className={classes.img}
                  />
                </Fade>
              </Grid>
            </Hidden>
          )}
          <Grid item sm={12} className={classes.textItem}>
            <Container maxWidth='md'>
              <Fade duration={1500}>
                <EcoIcon color='secondary' fontSize='large' />
                <Typography variant='h3'>{nachhaltigkeitHeading}</Typography>
                <Typography paragraph>{nachhaltigkeitText}</Typography>
              </Fade>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

NachhaltigkeitSection.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default withWidth()(NachhaltigkeitSection);

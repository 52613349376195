import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  Tabs,
  Tab,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  withWidth
} from '@material-ui/core';
import TabCarousel from '../../../layout/TabCarousel';

import Fade from 'react-reveal/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '70px 0',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0 0 0'
    }
  },
  tab: {
    margin: 'auto'
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      height: '500px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '600px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '780px',
      marginTop: '20px'
    }
  },
  button: {
    margin: '15px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '0px auto 20px auto',
      width: '170px'
    }
  },
  img: {
    height: '100%',
    width: '100%'
  }
}));

function TabPanel(props) {
  const { children, value, index, classes, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth='xl'>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

const TabSection = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { width } = props;

  const [value, setValue] = useState(0);
  const [tabs, setTabs] = useState(null);

  useEffect(() => {
    setTabs(props.tabsData.tabs);
  }, [props.tabsData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          textColor='secondary'
        >
          {tabs?.map?.((tab, index) => {
            const { title } = tab.fields;

            return <Tab label={title} className={classes.tab} key={index} />;
          })}
        </Tabs>
        {tabs?.map?.((tab, index) => {
          const { title, images, text, linkTo } = tab.fields;

          return (
            <TabPanel
              value={value}
              index={index}
              className={classes.tabpanel}
              key={index}
            >
              <Grid
                container
                spacing={
                  width === 'lg' || width === 'xl' || width === 'md' ? 10 : 0
                }
                alignItems='center'
                justify='center'
                className={classes.gridContainer}
              >
                <Grid item md={6} xs={12}>
                  <Fade duration={1500}>
                    <TabCarousel images={images} />
                  </Fade>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Fade duration={1500}>
                    <div>
                      <Typography
                        variant='h3'
                        align={
                          width === 'sm' || width === 'xs' ? 'center' : 'left'
                        }
                      >
                        {title}
                      </Typography>
                      <Typography
                        paragraph
                        align={
                          width === 'sm' || width === 'xs' ? 'center' : 'left'
                        }
                      >
                        {text}
                      </Typography>
                      <Button
                        href={linkTo}
                        variant='outlined'
                        color='secondary'
                        className={classes.button}
                      >
                        Mehr erfahren
                      </Button>
                    </div>
                  </Fade>
                </Grid>
              </Grid>
            </TabPanel>
          );
        })}
      </Container>
    </div>
  );
};

TabSection.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
};

export default withWidth()(TabSection);

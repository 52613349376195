import 'date-fns';
import React, { useState } from 'react';
import { useStyles } from './BubbleStyles';
import clsx from 'clsx';
import {
  Card,
  Button,
  CardActions,
  Typography,
  CardHeader,
  Grid,
  Container,
  CardContent
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Prices from './Prices';
import AmenityIcons from './AmenityIcons';
import BookingForm from './BookingForm';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptionsCardText } from '../../../contentfulOptionsCardText';

const Bubble = ({ bubble }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { name, subheading, image, cardText, ausstattung } = bubble.fields;

  const imageURL = image.fields.file.url;
  const imageTitle = image.fields.title;

  return (
    <div>
      <Card className={classes.card}>
        <Container maxWidth='md'>
          <Grid container spacing={3} justify='center' alignItems='flex-start'>
            <Grid item xs={12}>
              <CardHeader
                title={name}
                subheader={subheading}
                className={classes.cardHeader}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <CardContent>
                <img src={imageURL} alt={imageTitle} className={classes.img} />
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={7}>
              <CardContent>
                {documentToReactComponents(cardText, contentfulOptionsCardText)}
              </CardContent>
              <CardContent>
                <Prices bubble={bubble} />
              </CardContent>
              <CardContent>
                <AmenityIcons amenities={ausstattung} />
              </CardContent>
            </Grid>
          </Grid>
        </Container>
        <CardActions disableSpacing>
          <Button
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
            variant='contained'
            color='primary'
          >
            {!expanded && (
              <Typography variant='button'>JETZT BUCHEN</Typography>
            )}
            <ExpandMoreIcon />
          </Button>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <Container maxWidth='lg'>
            <CardContent>
              <BookingForm bubble={bubble} />
            </CardContent>
          </Container>
        </Collapse>
      </Card>
    </div>
  );
};

export default Bubble;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';
import { Paper, Container, Typography, withWidth } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '90px 0 30px 0',
    backgroundColor: '#EDECD130'
  },
  paper: {
    padding: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px'
    },
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'scale-down'
  }
}));

const Item = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant='h4'>{props.item.fields.heading}</Typography>
      <Typography paragraph>{props.item.fields.subheading}</Typography>
      <div style={{ height: '57vh' }}>
        <img
          src={props.item.fields.image.fields.file.url}
          alt={props.item.fields.image.fields.title}
          className={classes.img}
        />
      </div>
    </Paper>
  );
};

const Galerie = (props) => {
  const classes = useStyles();
  const { width } = props;

  const [images, setImages] = useState(null);

  useEffect(() => {
    fetch('/.netlify/functions/getContentfulData/?content_type=gallerie')
      .then((res) => res.json())
      .then(({ data }) => setState(data))
      .catch(console.log);
  }, []);

  const setState = (data) => {
    const { images } = data;

    setImages(images);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Carousel
          navButtonsAlwaysVisible={width !== 'xs' ? true : false}
          autoPlay={width !== 'xs' ? true : false}
          cycleNavigation
          animation='fade'
          indicators={false}
        >
          {images?.map?.((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default withWidth()(Galerie);

import React, { useState, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { contentfulOptions } from '../../../contentfulOptions';

const Hero = (props) => {
  const [heroText, setHeroText] = useState(null);

  useEffect(() => {
    const { heroText } = props.heroData;

    setHeroText(heroText);
  }, [props.heroData]);

  return <>{documentToReactComponents(heroText, contentfulOptions)}</>;
};

export default Hero;

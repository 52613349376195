import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles((theme) => ({
  carousel: {
    height: '100%'
  },
  item: {
    [theme.breakpoints.up('md')]: {
      height: '300px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '400px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '400px'
    }
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'scale-down'
  }
}));

const Item = (props) => {
  const classes = useStyles();
  const { height } = props;

  const url = props.item?.fields.file.url;
  const title = props.item?.fields.title;

  return (
    <div className={classes.item} style={{ height: height }}>
      <img src={url} alt={title} className={classes.img} />
    </div>
  );
};

const TabCarousel = (props) => {
  const classes = useStyles();
  const { images, height } = props;

  return (
    <div className={classes.carouselDiv}>
      <Carousel className={classes.carousel} autoPlay={false}>
        {images?.map?.((item, i) => (
          <Item key={i} item={item} height={height} />
        ))}
      </Carousel>
    </div>
  );
};

export default TabCarousel;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '35px 0'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export default function EssenTrinkenAccordion(props) {
  const classes = useStyles();

  const [accordions, setAccordions] = useState(null);

  useEffect(() => {
    const { accordions } = props;

    setAccordions(accordions);
  }, [props]);

  return (
    <div className={classes.root}>
      {accordions?.map?.((accordion, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={classes.heading}>
                {accordion.fields.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body2'>{accordion.fields.text}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

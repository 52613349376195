import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  navbarEmptySpace: {
    height: '70px',
    backgroundColor: '#EDECD130'
  }
}));

const NavbarEmptySpace = () => {
  const classes = useStyles();

  return <div className={classes.navbarEmptySpace}></div>;
};

export default NavbarEmptySpace;
